<!--团队管理：人员管理-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
        <h2 class="h2Style">
          人员管理
        </h2>
        <!--搜索栏-->
        <div class="control-box1">
          <div class="cb-left">
            <el-input
              v-model.trim="form.name"
              clearable
              placeholder="昵称/手机号"
              style="width: 200px;"
              @clear="form.name = undefined"
              @keyup.enter.native="handleFilter"
            />
            <el-form ref="form" class="control-item" :model="form" label-width="80px">
              <el-form-item label="账号角色：" label-width="100px" class="form-item">
                <el-select v-model="form.roleId" clearable>
                  <el-option
                    v-for="(item,itemRoles) in roleIds"
                    :key="itemRoles"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="所在班组：" label-width="100px" class="form-item">
                <el-select v-model="form.teamGroupId" clearable>
                  <el-option
                      v-for="(item,indexTeam) in team"
                      :key="indexTeam"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-form>
            <el-button
              class="control-item"
              icon="el-icon-search"
              type="primary"
              @click="handleFilter"
            >
              搜索
            </el-button>
          </div>

          <div class="cb-right">
            <el-button
              type="primary"
              v-if="managePermission"
              icon="el-icon-plus"
              @click="addTeamUserShow()"
            >
              添加
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
            v-loading="listLoading"
            :data="teamUser"
            element-loading-text="Loading"
            border
            highlight-current-row
            style="width: 100%"
          >
            <!--昵称-->
            <el-table-column label="昵称" align="center" width="150">
              <template slot-scope="scope">
                {{ scope.row.cName }}
              </template>
            </el-table-column>
            <!--性别-->
            <el-table-column label="性别" align="center" width="100">
              <template slot-scope="scope">
                {{ scope.row.gender }}
              </template>
            </el-table-column>
            <!--角色-->
            <el-table-column label="账号角色" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.roleName === ''">-</span>
                <span v-else>{{ scope.row.roleName }}</span>
              </template>
            </el-table-column>
            <!--手机号-->
            <el-table-column label="手机号" align="center" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.cPhone }}</span>
              </template>
            </el-table-column>
            <!--邮箱-->
            <el-table-column label="邮箱" align="center" width="150">
              <template slot-scope="scope">
                {{ scope.row.cEmail }}
              </template>
            </el-table-column>
            <el-table-column label="所在班组" align="center" width="150">
              <template slot-scope="scope">
                {{ scope.row.groupName }}
              </template>
            </el-table-column>
            <!--注册时间-->
            <el-table-column prop="created_at" label="注册时间" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.dCreateDate }}</span>
              </template>
            </el-table-column>
            <!--操作-->
            <!--        v-if="managePermission"-->
            <el-table-column label="操作" align="center" width="170">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  v-if="scope.row.leader !== '1' && managePermission"
                  size="mini"
                  @click="updateTeamUser(scope.row)"
                >
                  编辑
                </el-button>

                <el-button
                  v-if="scope.row.leader !== '1' && managePermission"
                  size="mini"
                  icon="el-icon-delete"
                  type="primary"
                  plain
                  @click="deleteTeamUser(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.count"
            :total="totalCount"
            :current-page="form.page + 1"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加账号-->
      <el-dialog
        v-if="TeamUserShow"
        :visible.sync="TeamUserShow"
        title="添加账号"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        @close="close1()"
      >
        <el-form ref="inviteForm" label-width="100px" :model="invite" :rules="rules.inviteRules">
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="invite.phone"
              show-word-limit
              maxlength="11"
              clearable
              placeholder="输入手机号"
              @input="changeValue"
            />
<!--     size="medium"       -->
          </el-form-item>
          <el-form-item label="账号角色" prop="roleId">
            <el-select
              v-model="invite.roleId"
              multiple
              placeholder="请选择角色"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in roleIds"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="班组:" prop="">
            <el-select
                v-model="invite.teamGroupId"
                placeholder="请选择班组"
                clearable
                style="width: 100%"
            >
              <el-option
                  v-for="(item,line) in team"
                  :key="line"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="TeamUserShow = false">取 消</el-button>
          <el-button type="primary" :loading="addUserBtnLoading" @click="addTeamUser()">
            确定
          </el-button>
        </div>
      </el-dialog>

      <!--弹出框：编辑-->
      <el-dialog
        v-if="teamUserEditShow"
        :visible.sync="teamUserEditShow"
        title="编辑账号"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="true"
        @close="close2"
      >
        <div class="mr-70">
          <el-form ref="teamForm" :model="teamValues" :rules="rules.inviteRules">
            <el-form-item label="账号角色:" label-width="100px" class="">
              <el-select v-model="roleValues" multiple placeholder="请选择角色" style="width: 100%">
                <el-option
                  v-for="(item, indexRole) in roleIds"
                  :key="indexRole"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="班组:" label-width="100px" prop="">
              <el-select
                  v-model="teamValues.teamGroupId"
                  placeholder="请选择班组"
                  clearable
                  style="width: 100%"
              >
                <el-option
                    v-for="(item,itemTeam) in team"
                    :key="itemTeam"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="teamUserEditShow = false">取 消</el-button>
          <el-button type="primary" :loading="editDetailBtnLoading" @click="editTeamUserRoles">
            确定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { saveRole } from '@/api/team'
import API from '@/api';
// import { mapGetters } from 'vuex'
import valid from '@/utils/validator';
import { manageOauth } from '@/utils/validate';
// import { getTeamRoles } from '@/api/role'
export default {
  name: 'PersonManagement',
  data() {
    return {
      teamUser: [],
      TeamUserShow: false,
      teamUserEditShow: false,
      addUserBtnLoading: false,
      editDetailBtnLoading: false,
      invite: {
        phone: '',
        roleId: [],
        teamGroupId: '',
      },
      type: [],
      team: [],
      teamId: [
        { value: 0, label: '白班' },
        { value: 1, label: '晚班' },
      ],
      listLoading: false,
      roleIds: [],
      roleValues1: '',
      roleOptions: [],
      roleValues: [],
      teamValues: {
        teamGroupId: '',
      },
      curUserId: '',
      auth: {
        create: false,
        modify: false,
        delete: false,
      },
      leader: '',
      leaderRole: '',
      // 查询
      form: {
        name: '',
        teamGroupId: '',
        roleId: '',
        page: 0,
        count: 10,
      },
      formClass: {
        name: '',
        page: 1,
        count: 100000000,
      },
      rules: {
        inviteRules: {
          phone: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                valid.checkNull(rule, value, callback, '请输入手机号', false);
                valid.checkByRegex(rule, value, callback, valid.REG_PHONE, '手机号不合法');
              },
            },
          ],
          teamGroupId: [{ required: true, message: '请选择班组', trigger: 'change' }],
          teamValues: [{ required: true, message: '请选择班组', trigger: 'change' }],
        },
      },
      totalCount: 0,
      buttons: '',
    };
  },
  computed: {
    // ...mapGetters([
    //   'buttons'
    // ]),
    // viewPermission: {
    //   get: function() {
    //     return viewOauth('team_view', this.buttons)
    //   }
    // },
    managePermission: {
      get() {
        return manageOauth('team_operate', this.buttons);
      },
    },
  },
  created() {
    API.userLog({ code: 'webPersonManage' }).then(() => {
    });
    // console.log(this.$route.query)
    // 如果路由有角色id,显示'添加账号'弹窗，'账号角色'默认选择相关角色id
    if (this.$route.query.roleId) {
      this.invite.roleId = [this.$route.query.roleId];
      this.TeamUserShow = true;
    }

    if (this.$store.getters.userInfo.buttons) {
      this.buttons = this.$store.getters.userInfo.buttons;
    } else {
      this.buttons = sessionStorage.getItem('buttons');
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('buttons', JSON.stringify(this.$store.getters.userInfo.buttons));
    });
  },
  mounted() {
    this.getTeamUser();
    this.getRouter();
  },
  methods: {
    changeValue(value) {
      this.invite.phone = value.replace(/[^\d]/g, '');
    },
    getTeamUser() {
      API.getTeamUser(this.form).then((response) => {
        this.teamUser = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    getRouter() {
      // 角色
      API.CompanyRole().then((res) => {
        // this.roleIds = [
        //   { id: '', name: '全部' }
        // ]
        res.message.data.forEach((item) => {
          this.roleIds.push(item);
        });
      });
      // 班组
      API.getClassDate(this.formClass).then((response) => {
        response.message.data.list.forEach((line) => {
          this.team.push(line);
        });
      });
    },
    handleFilter() {
      this.form.count = 10;
      this.form.page = 0;
      this.getTeamUser();
    },
    deleteTeamUser(val) {
      this.$confirm('确定删除当前账号吗？', '删除', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          API.deleteUser({ teamUserId: val.id }).then((response) => {
            this.$message({
              message: response.message.message,
              showClose: true,
              type: 'success',
            });
            if (this.teamUser.length === 1 && this.form.page !== 0) {
              this.form.page -= 1;
            }
            this.getTeamUser();
          });
        });
    },
    getTeamRoles(teamUser) {
      if (teamUser.leader === '1') {
        this.leader = teamUser.userId;
      }
      API.CompanyRole().then((response) => {
        this.roleIds = response.message.data;
        // console.log(response)
        // for (const info of response.data) {
        //   if (info.name === '公司管理员') {
        //     this.leaderRole = info.id
        //   }
        //   if (teamUser.leader !== '1' || info.name !== '公司管理员') {
        //     this.roleOptions.push({
        //       value: info.id,
        //       label: info.name
        //     })
        //   }
        // }
      });
    },
    // 编辑
    updateTeamUser(teamUser) {
      console.log(teamUser);
      this.getTeamRoles(teamUser);
      const values = teamUser.roleIds;
      const names = teamUser.roleName;
      // console.log(values)
      if (values) {
        this.roleValues = values;
        if (this.roleValues.length < 2){
          this.roleValues = ''
        }
        // console.log(values)
        // console.log(this.roleIds)
        const aaa = []
        this.roleIds.map(x => {
          values.map(y => {
            if (x.id === y) {
              aaa.push(y)
              this.roleValues = aaa;
            }
            // console.log(x.id,y)
          })
        })
        console.log(aaa)
        if (teamUser.leader === '1') {
          if (names === '公司管理员') {
            this.roleValues = [];
          } else {
            // var index = names.indexOf('公司管理员')
            // if (index !== -1) {
            //   this.roleValues.splice(index, 1)
            // }
          }
        }
      }
      this.teamUserEditShow = true;
      this.teamValues.teamGroupId = teamUser.teamGroupId;
      this.curUserId = teamUser.userId;
    },
    saveRole() {
      let roleIds = this.roleValues.join();
      if (this.curUserId === this.leader) {
        roleIds = `${this.leaderRole},${roleIds}`;
      }
      if (this.roleValues.length === 0) {
        roleIds = null;
      }
      this.$refs.teamForm.validate((v) => {
        if (v) {
          this.editDetailBtnLoading = true;
          API.saveRole({ userId: this.curUserId, roleId: roleIds, teamGroupId: this.teamValues.teamGroupId })
            .then((response) => {
              this.editDetailBtnLoading = false;
              if (response.message.success === true) {
                this.$message({
                  message: response.message.message,
                  showClose: true,
                  type: 'success',
                });
                this.teamUserEditShow = false;
                this.getTeamUser();
              } else {
                this.$message({
                  message: response.message.message,
                  showClose: true,
                  type: 'error',
                });
              }

              this.teamUserEditShow = false;
              this.getTeamUser();
            })
            .catch(() => {
              this.editDetailBtnLoading = false;
            });
        }
      });
    },
    addTeamUserShow() {
      // console.log(this.roleIds)
      this.invite.teamGroupId = '';
      this.invite.phone = '';
      this.invite.roleId = '';
      this.TeamUserShow = true;
    },

    editTeamUserRoles() {
      this.saveRole();
    },
    close1() {
      this.TeamUserShow = false;
    },
    close2() {
      this.roleValues = [];
      this.roleOptions = [];
    },
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          this.addUserBtnLoading = true;
          const params = JSON.parse(JSON.stringify(this.invite));
          console.log(params);
          params.roleId = this.invite.roleId.join(',');
          API.invite(params)
            .then((response) => {
              // console.log(response.message.success)
              if (response.message.success) {
                this.addUserBtnLoading = false;
                this.$message({
                  message: response.message.message,
                  // showClose: true,
                  type: 'success',
                });
                this.TeamUserShow = false;
                this.invite.phone = '';
                this.invite.roleId = '';
                this.invite.teamGroupId = '';
                this.getTeamUser();
              } else if (!response.message.success) {
                this.addUserBtnLoading = false;
                this.$message({
                  message: response.message.message,
                  // showClose: true,
                  type: 'error',
                });
              }
            })
            .catch(() => {
              this.addUserBtnLoading = false;
            });
        } else {
          return false;
        }
        return v;
      });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 0;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.form.page = val - 1;
      this.getTeamUser();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
</style>
